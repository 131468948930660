import Axios from "axios";
import { LocationResult } from "../interfaces/LocationResult";
import { calculateDistanceBetween } from "./Utilities";

export interface CurrentLocationType {
  currentLocation: google.maps.LatLngLiteral;
  geolocationPermissionsResult: "ACCEPTED" | "REJECTED";
}

let currentLocation: google.maps.LatLngLiteral;

export class LocationService {
  static GetCurrentLocation(
    withPermission: boolean,
    geolocationKey: string
  ): Promise<CurrentLocationType> {
    let locationTrackingCounter = false;
    const fetchOnline = (
      resolve: ( value: google.maps.LatLngLiteral ) => void,
      reject: ( reason: Error ) => void
    ) => {
      if ( currentLocation ) return resolve( currentLocation );
      Axios.post<LocationResult>(
        "https://www.googleapis.com/geolocation/v1/geolocate",
        {},
        { params: { key: geolocationKey } }
      )
        .then( ( result ) => {
          currentLocation = {
            lat: result.data.location.lat,
            lng: result.data.location.lng
          };
          return resolve( currentLocation );
        } )
        .catch( reject );
    };

    return new Promise( ( resolve, reject ) => {
      if ( withPermission && navigator.geolocation ) {
        navigator.geolocation.getCurrentPosition(
          ( { coords } ) => {
            if ( !locationTrackingCounter )
              ( window as unknown as {
                dataLayer: Array<unknown>
              } ).dataLayer.push( {
                'event': 'location_tracking_accepted'
              } );
            locationTrackingCounter = true;
            resolve( {
              currentLocation: {
                lat: coords.latitude,
                lng: coords.longitude
              },
              geolocationPermissionsResult: "ACCEPTED"
            } )
          },
          ( positionError ) => {
            if (
              positionError.code === GeolocationPositionError.PERMISSION_DENIED
            ) {
              if ( !locationTrackingCounter )
                ( window as unknown as {
                  dataLayer: Array<unknown>
                } ).dataLayer.push( {
                  'event': 'location_tracking_declined'
                } );
              locationTrackingCounter = true;
              fetchOnline(
                ( result ) =>
                  resolve( {
                    currentLocation: result,
                    geolocationPermissionsResult: "REJECTED"
                  } ),
                reject
              );
            } else {
              reject( positionError );
            }
          },
          { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
        );
      } else {
        fetchOnline(
          ( result ) =>
            resolve( {
              currentLocation: result,
              geolocationPermissionsResult: "REJECTED"
            } ),
          reject
        );
      }
    } );
  }

  static GetClosestPoint(
    center: google.maps.LatLngLiteral | google.maps.LatLng,
    locations: ReadonlyArray<google.maps.LatLngLiteral>
  ) {
    const _center = {
      lat: typeof center.lat === "function" ? center.lat() : center.lat,
      lng: typeof center.lng === "function" ? center.lng() : center.lng
    };
    return locations.reduce(
      ( acc, current ) =>
        calculateDistanceBetween( acc, _center ) <
        calculateDistanceBetween( current, _center )
          ? acc
          : current,
      locations[ 0 ]
    );
  }
}
