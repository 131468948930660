import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_ZOOM } from "../Constants";
import CurrentLocationPin from "../icons/CurrentLocationPin.svg";
import { LocationService } from "../services/LocationService";
import { isMobile } from "../services/Utilities";
import { CenterMapButton } from "./CenterMapButton";
import _ from "lodash";
import { useGoogleAnalyticsContext } from "../services/GoogleAnalyticsContext";

interface MapProps {
  locations: ReadonlyArray<google.maps.LatLngLiteral>;
  locationsLoading: boolean;
  onMarkerClick: (data: google.maps.LatLngLiteral) => void;
  center?: google.maps.LatLngLiteral;
  userLocation?: google.maps.LatLngLiteral;
  onCenterChange?: (newCenter: google.maps.LatLngLiteral) => void;
  onMapLoaded?: (map: google.maps.Map) => void;
  map: google.maps.Map | undefined;
  mapId: string | undefined;
  locationPermission: "ACCEPTED" | "REJECTED" | undefined;
  setShowPermissionText: (arg0: boolean) => void;
  storePinSVG: string;
}

const adjustMapToLocations = ({
  locations,
  map,
  center,
}: {
  locations: ReadonlyArray<google.maps.LatLngLiteral>;
  map: google.maps.Map | null | undefined;
  center: google.maps.LatLngLiteral;
}) => {
  if (!map) return;
  if (locations.length === 0) return;

  const closest = LocationService.GetClosestPoint(center, locations);

  const bounds = new google.maps.LatLngBounds();
  bounds.extend(center).extend(closest);
  map.fitBounds(bounds);
};

export const GoogleMaps = ({
  locations,
  locationsLoading,
  center,
  userLocation,
  onCenterChange,
  onMarkerClick,
  onMapLoaded,
  map,
  mapId,
  locationPermission,
  setShowPermissionText,
  storePinSVG,
}: MapProps) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [firstLoad, setFirstLoad] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const markers = useRef<MarkerClusterer>();
  const _isMobile = isMobile();
  const timeout = useRef<NodeJS.Timeout>();
  const currentMarkers = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
  const userLocationMarker = useRef<google.maps.marker.AdvancedMarkerElement>();
  const googlePushEvent = useGoogleAnalyticsContext();
  let mapLoaded = false;
  const addLocationsToMap = useCallback(
    (locations: ReadonlyArray<google.maps.LatLngLiteral>) => {
      const markers = locations.map((position) => {
        const pinSvg = document.createElement("img");
        pinSvg.src = storePinSVG;
        const newMarker = new google.maps.marker.AdvancedMarkerElement({
          position,
          map,
          content: pinSvg,
        });
        newMarker.addListener("click", () => {
          googlePushEvent("click_location_map");
          onMarkerClick(position);
        });
        return newMarker;
      });

      return markers;
    },
    [googlePushEvent, map, onMarkerClick],
  );

  const addClusterMarkers = useCallback(
    ({
      locations,
      map,
      cluster,
    }: {
      locations: ReadonlyArray<google.maps.LatLngLiteral>;
      map: google.maps.Map | null | undefined;
      cluster?: MarkerClusterer;
    }) => {
      if (!cluster || !map) return;

      const mapBounds = map.getBounds();

      const locationsToAdd = locations.filter((item) => {
        return (
          mapBounds?.contains(item) &&
          !currentMarkers.current.some((marker) => {
            const markerPosition =
              marker.position && "toJSON" in marker.position
                ? marker.position?.toJSON()
                : marker.position;
            return (
              !!markerPosition &&
              markerPosition.lng === item.lng &&
              markerPosition.lat === item.lat
            );
          })
        );
      });
      const markersToRemove = currentMarkers.current.filter((item) => {
        const position =
          item.position && "toJSON" in item.position
            ? item.position?.toJSON()
            : item.position;
        return !!position && !mapBounds?.contains(position);
      });

      if (locationsToAdd.length === 0 && markersToRemove.length === 0) return;

      const markersToAdd = addLocationsToMap(locationsToAdd);
      cluster.addMarkers(markersToAdd, true);
      cluster.removeMarkers(markersToRemove, true);

      cluster.render();
      cluster.render();
      currentMarkers.current = currentMarkers.current
        .filter((item) => {
          const position =
            item.position && "toJSON" in item.position
              ? item.position?.toJSON()
              : item.position;
          return !!position && mapBounds?.contains(position);
        })
        .concat(markersToAdd);
    },
    [addLocationsToMap],
  );

  useEffect(() => {
    if (!ref.current || map) return;

    const _map = new window.google.maps.Map(ref.current, {
      center: center,
      zoom: zoom,
      disableDefaultUI: true,
      zoomControl: !_isMobile,
      clickableIcons: false,
      mapId,
    });
    const debouncedMapValues = _.debounce(() => {
      return {
        zoom: _map.getZoom()?.toString(),
        center: _map.getCenter()?.toString(),
      };
    }, 1000);

    const debouncedMapZoom = _.debounce(() => {
      googlePushEvent("map_zoom", {
        initial_zoom_level: initialZoomLevel,
        adjusted_zoom_level: adjustedZoomLevel,
        initial_center_level: initialCenterLevel,
        adjusted_center_level: adjustedCenterLevel,
      });
    }, 1000);

    const initialZoomLevel =
      _map.getZoom()?.toString() ?? DEFAULT_ZOOM.toString();
    const adjustedZoomLevel =
      debouncedMapValues()?.zoom ?? DEFAULT_ZOOM.toString();
    const initialCenterLevel = _map.getCenter()?.toString() ?? "";
    const adjustedCenterLevel = debouncedMapValues()?.center ?? "";

    _map.addListener("idle", () => {
      if (!_map) return;
      mapLoaded = true;
      const currentZoom = _map.getZoom();
      const newCenter = _map.getCenter();
      if (currentZoom !== zoom) setZoom(_map.getZoom() ?? DEFAULT_ZOOM);

      if (!onCenterChange || !newCenter) return;

      timeout.current && clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onCenterChange({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
      }, 500);
    });

    _map.addListener("dragend", () => {
      googlePushEvent("drag_map", {
        initial_zoom_level: initialZoomLevel,
        adjusted_zoom_level: adjustedZoomLevel,
        initial_center_level: initialCenterLevel,
        adjusted_center_level: adjustedCenterLevel,
      });
    });

    _map.addListener("zoom_changed", () => {
      if (!mapLoaded) return;
      debouncedMapZoom();
    });

    onMapLoaded && onMapLoaded(_map);

    markers.current = new MarkerClusterer({
      markers: [],
      map: _map,
      algorithm: new SuperClusterAlgorithm({
        radius: 175, // cluster size
      }),
    });
  }, [
    _isMobile,
    center,
    googlePushEvent,
    locations,
    map,
    mapId,
    onCenterChange,
    onMapLoaded,
    zoom,
  ]);

  useEffect(() => {
    if (!userLocation || !map) return;

    if (userLocationMarker.current) {
      userLocationMarker.current.position = userLocation;
    } else {
      const pinSvg = document.createElement("img");
      pinSvg.src = CurrentLocationPin;

      userLocationMarker.current = new google.maps.marker.AdvancedMarkerElement(
        {
          position: userLocation,
          map,
          content: pinSvg,
        },
      );
      map.setCenter(userLocation);
    }
  }, [map, userLocation]);

  useEffect(() => {
    if (!map) return;

    if (firstLoad && center && locations.length > 0) {
      adjustMapToLocations({ locations, map, center });
      setFirstLoad(false);
    }

    if (!locationsLoading)
      addClusterMarkers({
        cluster: markers.current,
        locations,
        map,
      });
  }, [addClusterMarkers, center, firstLoad, locations, locationsLoading, map]);
  const permissionsModalHandler = () => {
    setShowPermissionText(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
      }}
      ref={ref}
      onClick={permissionsModalHandler}
    >
      <CenterMapButton
        map={map}
        centerLocation={userLocation}
        locationPermission={locationPermission}
        setShowPermissionText={setShowPermissionText}
      />
    </div>
  );
};

export default GoogleMaps;
