import { createContext, useContext } from "react";
import i18n from "../locales/i18n";

type GoogleAnalyticsContextType = {
  country?: string | null;
  business_partner?: string;
};

export const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextType>(
  {},
);

export const useGoogleAnalyticsContext = () => {
  const { country, business_partner } = useContext(GoogleAnalyticsContext);
  const { language } = i18n;
  const googlePushEvent = (
    event: string,
    restEvents?: {
      [key: string]: string;
    },
  ) => {
    return window.dataLayer.push({
      event,
      language,
      ...(country ? { country } : undefined),
      ...(business_partner ? { business_partner } : undefined),
      ...restEvents,
    });
  };
  return googlePushEvent;
};
